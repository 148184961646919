<template>
	<b-input
		type="text"
		v-model="internalValue"
		:state="state"
		:disabled="disabled"
		@change="$emit('change', $event)"
	>
	</b-input>
</template>

<script>
export default {
	name: 'PriceInput',
	props: {
		value: [String, Number],
		disabled: {
			type: Boolean,
			default: () => (false)
		}
	},
	data: () => ({
		state: null,
		internalValue: null
	}),
	created() {
		this.internalValue = this.value
	},
	methods: {
		updateState() {
			let val = this.internalValue
			if(val === '') {
				this.state = null
				this.$emit('input', '')
				return
			}

			val = String(val).toFloat()

			if (isNaN(val)) {
				this.state = false
				this.$emit('input', '')
				return
			}

			this.state = null
			this.$emit('input', val.toString())
		}
	},
	watch: {
		value(val) {
			this.internalValue = val
		},
		internalValue(val) {
			this.updateState(val)
		}
	}
}
</script>
